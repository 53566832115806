// material
import { styled } from '@mui/material/styles';
import { Stack, Box, Grid, Container, Typography } from '@mui/material';
// utils
//
import { MotionInView, varFadeInDown, varFadeInUp } from '../../animate';

// ----------------------------------------------------------------------
const RootStyle = styled('div')(({ theme }) => ({
  textAlign: 'center',
  paddingTop: theme.spacing(20),
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('md')]: {
    textAlign: 'left'
  }
}));

// ----------------------------------------------------------------------

export default function LandingWhat() {
  return (
    <RootStyle>
      <Container maxWidth="lg">
        <Box sx={{ mb: { xs: 10 } }}>
          <MotionInView variants={varFadeInDown}>
            <Typography variant="h2" sx={{ textAlign: 'center', mb: 3 }}>
              Save time managing multiple repositories at scale.
            </Typography>
          </MotionInView>
        </Box>
        <Grid item xs={12}>
          <MotionInView variants={varFadeInUp}>
            <Stack spacing={2}>
              <Box>
                <Typography
                  display="inline"
                  fontWeight={'bold'}
                  sx={{
                    color: (theme) =>
                      theme.palette.mode === 'light' ? 'text.secondary' : 'common.white'
                  }}
                >
                  Maintain consistent coding standards across teams and projects by syncing
                  developer configuration files.
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: (theme) =>
                    theme.palette.mode === 'light' ? 'text.secondary' : 'common.white'
                }}
              >
                No longer worry about manually configuring 'github actions' or creating shared 'npm'
                packages to keep files in sync.
              </Typography>
            </Stack>
          </MotionInView>
        </Grid>
      </Container>
    </RootStyle>
  );
}
