// material
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, Grid, Container, Typography, Button, Link, Stack } from '@mui/material';
//
import { varFadeInUp, MotionInView } from '../../animate';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { useState } from 'react';
import { PATH_PAGE } from '../../../routes/paths';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(24, 0),
  backgroundImage:
    theme.palette.mode === 'light'
      ? `linear-gradient(180deg, ${theme.palette.grey[300]} 0%, ${alpha(
          theme.palette.grey[300],
          0
        )} 100%)`
      : 'none'
}));

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&:before': {
    display: 'none'
  }
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1)
  }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)'
}));

const problemsAndSolutionsList = [
  {
    id: 'developerConfig',
    title: 'Developer configuration files like .eslintrc, .babelrc, .gitignore, and more.',
    description:
      'Ensure all your developers are working off the same configuration files, no more having to manually check and compare files across multiple repositories.'
  },
  {
    id: 'legalFiles',
    title: 'Critical files like LICENSE, CODE_OF_CONDUCT.md, and README.md.',
    description:
      'Ensure that all your licenses and legal files are in sync and up to date, easily one click update in one place.'
  },
  {
    id: 'developmentChecklist',
    title: 'Development essentials such as PR Templates and Issue Templates.',
    description:
      'Improveme development experience by having a single PR Template that is managed across multiple repositories.'
  },
  {
    id: 'multipleRepositories',
    title:
      'Manage files across multiple source control repositories, e.g. Github,GitLab,Bitbucket etc.',
    description: 'NOTE: this feature is under development and will be available soon.'
  },
  {
    id: 'auditAndScan',
    title: 'Audit repositories for files that are missing or out of date.',
    description:
      'Have a single screen to view files in multiple repositories and verify that they match'
  }
];
// ----------------------------------------------------------------------

export default function LandingProblemsSolved() {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';

  const [expanded, setExpanded] = useState<string | false>();

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <RootStyle>
      <Container maxWidth="lg">
        <Grid container spacing={5} justifyContent="center">
          <Grid item xs={12} md={10}>
            <Stack spacing={3}>
              <MotionInView variants={varFadeInUp}>
                <Typography variant="h2">Why do i need Repo File Sync?</Typography>
              </MotionInView>

              <MotionInView variants={varFadeInUp}>
                <Typography
                  sx={{
                    color: isLight ? 'text.secondary' : 'common.white'
                  }}
                >
                  These are some of the files & folders our users no longer worry about
                </Typography>
              </MotionInView>

              <Box>
                {problemsAndSolutionsList.map(({ id, title, description }) => (
                  <Accordion key={id} expanded={expanded === id} onChange={handleChange(id)}>
                    <AccordionSummary aria-controls={id + '-content'} id={id + 'panel1d-header'}>
                      <Typography>{title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>{description}</Typography>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Box>

              <Typography
                sx={{
                  mb: 5,
                  color: isLight ? 'text.secondary' : 'common.white',
                  width: '100%',
                  textAlign: 'center'
                }}
              >
                Give it a try, it doesn't take long at all!
              </Typography>
              <Box sx={{ width: '100%', textAlign: 'center' }}>
                <Button
                  size="large"
                  variant="contained"
                  component={Link}
                  target="_blank"
                  href={PATH_PAGE.pricing}
                >
                  I want to try Repo File Sync!
                </Button>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
  );
}
